<template>
  <div class="flex-grow flex justify-center items-center">
    <div
      class="bg-gray-100 shadow rounded-none xl:rounded-cu py-10 px-5 xl:min-w-550 flex flex-col"
    >
      <div class="w-full flex justify-center mb-5 mt-3">
        <img src="/logos/48/logo@2x.png" class="w-24" />
      </div>
      <input
        type="text"
        class="bg-white border border-solid border-gray-300 w-full py-3 px-2 rounded-cu"
        placeholder="Saisissez le code"
        v-model="code"
      />
      <div class="flex flex-col xl:flex-row xl:justify-between mt-5">
        <div class="mb-3 xl:mb-0 xl:mr-5">
          Si vous n'avez pas encore recu de code, appuyez sur
          <a href="#" @click="onResend" class="mx-1">envoyer SMS</a
          >.<br />(Veuillez vérifier que vous avez bien ajouté votre numéro de
          téléphone mobile dans les paramétres)
        </div>
        <button
          @click="onVerify"
          type="button"
          class="bg-transparent border-2 border-solid border-dokBlue-ultra text-dokBlue-ultra px-5 py-2 rounded-cu cursor-pointer"
        >
          Confirmez
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      code: null
    };
  },
  mounted() {
    if (!this.$route.query.token) {
      this.$router.push({ name: "newSignIn" });
    }
  },
  methods: {
    ...mapActions("auth", [
      "VERIFY_TWO_FACTOR_TOKEN",
      "RESEND_TWO_FACTOR_TOKEN"
    ]),
    async onResend() {
      this.$vs.loading({ background: "#000" });
      const data = await this.RESEND_TWO_FACTOR_TOKEN({
        token: this.$route.query.token,
        onData: ({ ok, message }) => {
          this.$vs.notify({
            time: 4000,
            // title: ok ? "Success" : "Error",
            text: message,
            color: ok ? "success" : "danger",
            iconPack: "feather",
            icon: "icon-check"
          });
          this.$vs.loading.close();
        }
      });
    },
    async onVerify() {
      let _this = this;
      this.$vs.loading({ background: "#000" });
      const data = await this.VERIFY_TWO_FACTOR_TOKEN({
        token: this.$route.query.token,
        authToken: this.code,
        onData: ({ ok, user, data, message, errCode, isFirstLogin }) => {
          if (ok) {
            window.location = "/agenda";
          }
          this.$vs.notify({
            time: 4000,
            // title: ok ? "Success" : "Error",
            text: message,
            color: ok ? "success" : "danger",
            iconPack: "feather",
            icon: "icon-check"
          });
          // setTimeout(() => {
          //   _this.$router.push("/");
          // }, 4000);
          this.$vs.loading.close();
        }
      });
    }
  }
};
</script>

<style lang="scss">
button,
input {
  font-size: 16px;
}
</style>
